import * as Sentry from '@sentry/browser'

const logException = e => {
  let error;

  if (isError(e)) {
    error = e;
  } else if (isXHR(e) && e.status !== 422 && e.status !== 401) {
    error = new ResponseError(e);
  }

  if (error) {
    console.warn(error);
    Sentry.captureException(error);
  }
};

const isError = e => e && e.stack && e.message;

const isXHR = e => e && e.responseText && e.status;

class ResponseError extends Error {
  constructor(xhr) {
    super(xhr.responseText);
    this.name = `ResponseError : ${xhr.status}`;
  }
}

export default logException;
