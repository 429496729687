import { I18n as I18nBase } from 'i18n-js';
import locales from 'locales.json';

export default function setupLocale() {
  const localeMetaElement = document.getElementById('js-locale');

  if (typeof window.I18n === 'undefined') {
    const I18n = new I18nBase(locales);
    window.I18n = I18n;
  }

  if (localeMetaElement) {
    I18n.defaultLocale = localeMetaElement.dataset.defaultLocale;
    I18n.locale = localeMetaElement.dataset.locale;
  } else {
    I18n.defaultLocale = 'en';
    I18n.locale = 'en';
  }
}
