/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_packs_with_chunks_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import initializeSentry from 'helpers/sentry.js.erb';
import setSentryTags from 'helpers/set_sentry_tags.js.erb';
initializeSentry();
setSentryTags();

import 'styles/react-widgets';
import 'styles/intl-tel-input';
import '@hotwired/turbo-rails';

import handleHistoryChange from 'components/common/handle_history_change';
import bindGlobalJqueryListeners from 'components/common/bind_global_jquery_listeners';
import setupLocale from 'helpers/setupLocale';

bindGlobalJqueryListeners();
handleHistoryChange();
setupLocale();

window.removeOldGtag = function() {
  let scripts = document.querySelectorAll('script[src*="pagead2.googlesyndication.com/pagead/conversion/"]');

  scripts.forEach(function(script) {
    if (script.src.includes('label=')) {
      script.remove();
    }
  });
}

document.addEventListener('turbo:before-cache', function() {
  window.removeOldGtag();
});
