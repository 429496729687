import logException from 'components/common/log_exception';

export default function bindGlobalJqueryListeners() {
  $(document).ajaxSuccess((_event, xhr) => {
    let redirect_path = xhr.getResponseHeader('SPECIAL_EVENT_REDIRECT');
    if (redirect_path) Turbo.visit(redirect_path);
  });

  $(document).ajaxError((_event, xhr) => {
    logException(xhr);
  });

  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
    },
  });
}
